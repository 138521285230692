.extend-region {
	background: rgba(0,0,0,0);
/*    background: green;
    opacity: 0.5;*/
    position: absolute;
    z-index: 10000;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.main-region {
    /*background: red;*/
    top: 0px;
    left: 0px;
    min-width: 980px;
    margin-left: 235px;
    margin-top: 50px;
    pointer-events: auto;
}
.boxSpinerLoaderMaps {
    position: absolute;
    top: 0px;
    background: rgba(255,255,255,.5);
    width: 100%;
    height: 100%;
    z-index: 1;
    bottom: 100px;
    left: 0px;
}
input[type=checkbox].inputShowBackground {
    margin-left: 29px;
    margin-top: 19px;
    margin-right: 5px;
}
label {
    user-select: none;
}