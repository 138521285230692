.controlPanel {
	position: fixed;
    width: 235px;
    top: 50px;
    bottom: 0px;
    background: #222934;
    left: 0px;
    z-index: 10000000;
    padding: 15px 14px 10px 15px;
    overflow: auto;
}
.controlPanelContainer {
	height: 100%;
	width: 100%;
}
.controlPanel::-webkit-scrollbar {
    width: 2px;
}
.controlPanel::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
.controlPanel::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  cursor: pointer;
}
/*.controlPanel::-webkit-scrollbar-thumb :horizontal:decrement:hover {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  width: 3px;
}*/
.controlPanelContainer .calendar.min:after {
	content: '';
    position: absolute;
    width: 6px;
    border-bottom: 1px solid #464d58;
    right: -11px;
    top: 13px;
    pointer-events: none;
}
.controlPanelObjectsOfMalls {
	background-color: #171c23;
    padding: 10px 9px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: inset 0px -1px 0px rgba(255,255,255,0.1);
    box-shadow: inset 0px -1px 0px rgba(255,255,255,0.1);
    margin-bottom: 10px;
}
.controlPanelObjectsOfMalls .icon {
	margin-right: 5px;
	width: 12px;
    height: 12px;
	background: url(img/icons/pic.png) no-repeat center;
    background-position: -14px -12px;
    float: left;
}
.controlPanelObjectsOfMalls h2 {
	font-size: 12px;
    color: #95a1b1;
    margin: 0 0 14px 0;
    padding: 0;
    text-transform: uppercase;
    display: inline-block;
    float: left;
}
.controlPanelObjectsOfMalls ul {
	list-style-type: none;
    padding: 0;
    color: #fff;
    font-size: 13px;
    position: relative;
    max-height: 89px;
    overflow: auto;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
}
.controlPanelObjectsOfMalls ul li {
border-bottom-left-radius: 2px;
border-bottom-right-radius: 2px;
border-top-left-radius: 2px;
border-top-right-radius: 2px;
box-shadow: rgba(255, 255, 255, 0.0980392) 0px 1px 0px 0px inset;
box-sizing:border-box;
color:rgb(255, 255, 255);
display:list-item;
font-family:'Open Sans', sans-serif;
font-size:13px;
line-height:18.5714px;
list-style-type:none;
margin-bottom:3px;
margin-left:0px;
margin-right:0px;
margin-top:3px;
padding-bottom:5px;
padding-left:10px;
padding-right:10px;
padding-top:5px;
position:relative;
text-align:left;
width:185px;
-webkit-user-select
}
.controlPanelObjectsOfMalls button {
	width: 100%;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background-color: #2f3949;
    color: #c2c7cf;
    font-size: 13px;
    -webkit-box-shadow: 0 -1px 0 rgba(255,255,255,0.18);
    box-shadow: 0 -1px 0 rgba(255,255,255,0.18);
    -webkit-transition: background-color 0.1s ease-in-out;
    -moz-transition: background-color 0.1s ease-in-out;
    -o-transition: background-color 0.1s ease-in-out;
    -ms-transition: background-color 0.1s ease-in-out;
    transition: background-color 0.1s ease-in-out;
    display: inline-block;
    padding: 5px 12px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
}

.controlPanelPeriod {
	position: relative;
	background-color: #171c23;
    padding: 10px 9px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: inset 0px -1px 0px rgba(255,255,255,0.1);
    box-shadow: inset 0px -1px 0px rgba(255,255,255,0.1);
    margin-bottom: 10px;
}

.controlPanelPeriod .icon {
	margin-right: 5px;
	width: 12px;
    height: 12px;
	background: url(img/icons/pic.png) no-repeat center;
    background-position: -1px -1px;
    float: left;
}
.controlPanelPeriod h2 {
	font-size: 12px;
    color: #95a1b1;
    margin: 0 0 14px 0;
    padding: 0;
    text-transform: uppercase;
    display: inline-block;
    float: left;
}

.controlPanelInterval .icon {
	margin-right: 5px;
	width: 12px;
    height: 12px;
	background: url(img/icons/pic.png) no-repeat center;
    background-position: -27px -10px;
    float: left;
}
.controlPanelInterval h2 {
	font-size: 12px;
    color: #95a1b1;
    margin: 0 0 14px 0;
    padding: 0;
    text-transform: uppercase;
    display: inline-block;
    float: left;
}


.selectedControlPanel {
	width: 185px;
	height: 29px;
	border: 0px;
	font-size: 13px;
}
.ps-container::-webkit-scrollbar {
    width: 2px;
}
 
.ps-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
.ps-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.load {
	background: #171c23 url(img/icons/miniSpiner.svg) no-repeat center;
}

/*календарь*/
.rc-calendar {
  box-sizing: border-box;
}
.rc-calendar * {
  box-sizing: border-box;
}
.rc-calendar-hidden {
  display: none;
}
.rc-calendar-input-wrap {
  position: relative;
  padding: 6px;
  border-bottom: 1px solid #e9e9e9;
}
.rc-calendar-input-wrap:after {
  content: '';
  clear: both;
}
.rc-calendar-date-input-wrap {
  overflow: hidden;
}
.rc-calendar-time-picker-wrap {
  float: right;
  width: 170px;
}
.rc-calendar-time-picker-wrap .rc-time-picker {
  width: 100%;
}
.rc-calendar-time-picker-wrap .rc-time-picker-input {
  padding: 0;
  border: 1px solid transparent;
  outline: 0;
  height: 22px;
}
.rc-calendar-time-picker-wrap .rc-time-picker-icon {
  display: none;
}
.rc-calendar-input {
  border: 1px solid transparent;
  width: 100%;
  color: #666;
  cursor: text;
  line-height: 1.5;
  outline: 0;
  height: 22px;
}
.rc-calendar-input-invalid {
  border-color: red;
}
.rc-calendar-clear-btn {
  z-index: 999900000;
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 6px;
  margin: 0;
}
.rc-calendar-clear-btn:after {
  content: "x";
  font-size: 12px;
  color: #aaa;
  display: inline-block;
  line-height: 1;
  width: 20px;
  transition: color 0.3s ease;
}
.rc-calendar-clear-btn:hover:after {
  color: #666;
}
.rc-calendar-picker {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 100000000;
}
.rc-calendar-picker-hidden {
  display: none;
}
.rc-calendar-picker-slide-up-enter {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  display: block !important;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-calendar-picker-slide-up-appear {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  display: block !important;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-calendar-picker-slide-up-leave {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  display: block !important;
  opacity: 1;
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.rc-calendar-picker-slide-up-enter.rc-calendar-picker-slide-up-enter-active.rc-calendar-picker-placement-bottomLeft,
.rc-calendar-picker-slide-up-enter.rc-calendar-picker-slide-up-enter-active.rc-calendar-picker-placement-bottomRight,
.rc-calendar-picker-slide-up-appear.rc-calendar-picker-slide-up-appear-active.rc-calendar-picker-placement-bottomLeft,
.rc-calendar-picker-slide-up-appear.rc-calendar-picker-slide-up-appear-active.rc-calendar-picker-placement-bottomRight {
  -webkit-animation-name: rcDropdownSlideUpIn;
          animation-name: rcDropdownSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-calendar-picker-slide-up-enter.rc-calendar-picker-slide-up-enter-active.rc-calendar-picker-placement-topLeft,
.rc-calendar-picker-slide-up-enter.rc-calendar-picker-slide-up-enter-active.rc-calendar-picker-placement-topRight,
.rc-calendar-picker-slide-up-appear.rc-calendar-picker-slide-up-appear-active.rc-calendar-picker-placement-topLeft,
.rc-calendar-picker-slide-up-appear.rc-calendar-picker-slide-up-appear-active.rc-calendar-picker-placement-topRight {
  -webkit-animation-name: rcDropdownSlideDownIn;
          animation-name: rcDropdownSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-calendar-picker-slide-up-leave.rc-calendar-picker-slide-up-leave-active.rc-calendar-picker-placement-bottomLeft,
.rc-calendar-picker-slide-up-leave.rc-calendar-picker-slide-up-leave-active.rc-calendar-picker-placement-bottomRight {
  -webkit-animation-name: rcDropdownSlideUpOut;
          animation-name: rcDropdownSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.rc-calendar-picker-slide-up-leave.rc-calendar-picker-slide-up-leave-active.rc-calendar-picker-placement-topLeft,
.rc-calendar-picker-slide-up-leave.rc-calendar-picker-slide-up-leave-active.rc-calendar-picker-placement-topRight {
  -webkit-animation-name: rcDropdownSlideDownOut;
          animation-name: rcDropdownSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes rcDropdownSlideUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes rcDropdownSlideUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes rcDropdownSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}
@keyframes rcDropdownSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}
@-webkit-keyframes rcDropdownSlideDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes rcDropdownSlideDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@-webkit-keyframes rcDropdownSlideDownOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}
@keyframes rcDropdownSlideDownOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}
.rc-calendar {
  position: relative;
  outline: none;
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei", "Microsoft Sans Serif", "WenQuanYi Micro Hei", sans-serif;
  width: 253px;
  list-style: none;
  font-size: 12px;
  text-align: left;
  /*background-color: #fff;*/
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border: 1px solid #1f2630;
  line-height: 1.5;
}
.rc-calendar-date-panel {
  position: relative;
  outline: none;
}
.rc-calendar-week-number {
  width: 286px;
}
.rc-calendar-week-number-cell {
  text-align: center;
}
.rc-calendar-header {
  padding: 0 10px;
  height: 34px;
  line-height: 30px;
  text-align: center;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid #1f2630;
}
.rc-calendar-header > a {
  font-weight: bold;
  display: inline-block;
  padding: 0px 5px;
  line-height: 34px;
  text-align: center;
  width: 30px;
}
.rc-calendar-header > a:hover {
  cursor: pointer;
  color: #23c0fa;
}
.rc-calendar-header .rc-calendar-prev-month-btn {
  position: absolute;
  left: 25px;
}
.rc-calendar-header .rc-calendar-next-month-btn {
  position: absolute;
  right: 25px;
}
.rc-calendar-year-select,
.rc-calendar-month-select {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: #666;
  padding: 0 8px;
  line-height: 34px;
}
.rc-calendar-year-select:hover,
.rc-calendar-month-select:hover {
  cursor: pointer;
  color: #23c0fa;
}
.rc-calendar-prev-month-btn,
.rc-calendar-next-month-btn,
.rc-calendar-prev-year-btn,
.rc-calendar-next-year-btn {
  position: absolute;
  top: 0;
  cursor: pointer;
  color: #999;
  font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei", "Microsoft Sans Serif", sans-serif;
  padding: 0 5px;
  font-size: 16px;
  display: inline-block;
  line-height: 34px;
}
.rc-calendar-prev-month-btn:hover,
.rc-calendar-next-month-btn:hover,
.rc-calendar-prev-year-btn:hover,
.rc-calendar-next-year-btn:hover {
  color: #23c0fa;
}
.rc-calendar-next-year-btn {
  right: 0;
}
.rc-calendar-prev-year-btn {
  left: 0;
}
.rc-calendar-calendar-body {
  padding: 9px 10px 10px;
}
.rc-calendar table {
  border-collapse: collapse;
  max-width: 100%;
  background-color: transparent;
  width: 100%;
}
.rc-calendar table,
.rc-calendar td,
.rc-calendar th,
.rc-calendar td {
  border: none;
}
.rc-calendar-calendar-table {
  border-spacing: 0;
  margin-bottom: 0;
}
.rc-calendar-column-header {
  line-height: 18px;
  padding: 6px 0;
  width: 33px;
  text-align: center;
}
.rc-calendar-column-header .rc-calendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.rc-calendar-week-number-header .rc-calendar-column-header-inner {
  display: none;
}
.rc-calendar-cell {
  padding: 1px 0;
}
.rc-calendar-date {
  display: block;
  margin: 0 auto;
  color: #fff;
  border-radius: 4px 4px;
  width: 26px;
  height: 26px;
  padding: 0;
  background: transparent;
  line-height: 26px;
  text-align: center;
}
.rc-calendar-date:hover {
  background: #ebfaff;
  cursor: pointer;
}
.rc-calendar-selected-day .rc-calendar-date {
  background: #3fc7fa;
  color: #fff;
}
.rc-calendar-selected-day .rc-calendar-date:hover {
  background: #3fc7fa;
}
.rc-calendar-today .rc-calendar-date {
  border: 1px solid #3fc7fa;
}
.rc-calendar-disabled-cell .rc-calendar-date {
  cursor: not-allowed;
  color: #bcbcbc;
  background: #f3f3f3;
  border-radius: 0;
  width: auto;
}
.rc-calendar-disabled-cell .rc-calendar-date:hover {
  background: #f3f3f3;
}
.rc-calendar-disabled-cell-first-of-row .rc-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.rc-calendar-disabled-cell-last-of-row .rc-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.rc-calendar-last-month-cell .rc-calendar-date,
.rc-calendar-next-month-btn-day .rc-calendar-date {
  color: #666;
}
.rc-calendar-footer {
  border-top: 1px solid #1f2630;
  padding: 10px 0;
  text-align: center;
  position: relative;
}
.rc-calendar-footer .rc-time-picker {
  width: 90px;
}
.rc-calendar-footer .rc-time-picker-input {
  height: 24px;
}
.rc-calendar-footer-btn {
  margin-top: 2px;
}
.rc-calendar-today-btn,
.rc-calendar-ok-btn {
  display: inline-block;
  padding: 4px 5px;
  text-align: center;
  color: #f46830;
}
.rc-calendar-today-btn:hover,
.rc-calendar-ok-btn:hover {
  cursor: pointer;
  color: #23c0fa;
}
.rc-calendar-today-btn-disabled,
.rc-calendar-ok-btn-disabled {
  color: #bbb;
}
.rc-calendar-today-btn-disabled:hover,
.rc-calendar-ok-btn-disabled:hover {
  color: #bbb;
}
.rc-calendar-today-btn {
  padding-left: 10px;
}
.rc-calendar-time-input {
  height: 25px;
  position: relative;
  display: inline-block;
  margin: 0 0;
  padding: 4px 10px;
  border-radius: 6px 6px;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  color: #666;
  line-height: 1.5;
  -webkit-transform: border 0.3s cubic-bezier(0.35, 0, 0.25, 1), background 0.3s cubic-bezier(0.35, 0, 0.25, 1), box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1);
          transform: border 0.3s cubic-bezier(0.35, 0, 0.25, 1), background 0.3s cubic-bezier(0.35, 0, 0.25, 1), box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  width: 40px;
}
.rc-calendar-time-input:hover {
  border-color: #23c0fa;
}
.rc-calendar-time-input:focus {
  border-color: #23c0fa;
  box-shadow: 0 0 3px #23c0fa;
}
.rc-calendar-time-panel {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 1000000;
  position: absolute;
  outline: none;
}
.rc-calendar-time-panel-header {
  padding: 0 10px;
  height: 34px;
  line-height: 34px;
  position: relative;
  text-align: center;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid #ccc;
}
.rc-calendar-time-panel-body {
  padding: 9px 10px 10px;
}
.rc-calendar-time-panel-title {
  width: 180px;
  font-weight: bold;
  display: inline-block;
  padding: 4px 5px;
  text-align: center;
  height: 30px;
  line-height: 22px;
  border-radius: 4px;
}
.rc-calendar-time-panel-table {
  table-layout: fixed;
  width: 100%;
  height: 255px;
  border-collapse: separate;
}
.rc-calendar-time-panel-cell {
  text-align: center;
  height: 42px;
  vertical-align: middle;
}
.rc-calendar-time-panel-time {
  line-height: 26px;
  display: block;
  border-radius: 4px;
  width: 26px;
  margin: 0 auto;
}
.rc-calendar-time-panel-time:hover {
  background: #ebfaff;
  cursor: pointer;
}
.rc-calendar-time-panel-selected-cell .rc-calendar-time-panel-time {
  background: #3fc7fa;
  color: #fff;
}
.rc-calendar-time-panel-selected-cell .rc-calendar-time-panel-time:hover {
  background: #3fc7fa;
  color: #fff;
}
.rc-calendar-month-panel {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 1000000;
  position: absolute;
  outline: none;
}
.rc-calendar-month-panel-hidden {
  display: none;
}
.rc-calendar-month-panel-header {
  padding: 0 10px;
  height: 34px;
  line-height: 30px;
  position: relative;
  text-align: center;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid #ccc;
}
.rc-calendar-month-panel-header > a {
  font-weight: bold;
  display: inline-block;
  padding: 4px 5px;
  text-align: center;
  width: 30px;
}
.rc-calendar-month-panel-header > a:hover {
  cursor: pointer;
  color: #23c0fa;
}
.rc-calendar-month-panel-prev-year-btn,
.rc-calendar-month-panel-next-year-btn {
  position: absolute;
  top: 0;
}
.rc-calendar-month-panel-prev-year-btn {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  left: 0;
}
.rc-calendar-month-panel .rc-calendar-month-panel-year-select {
  width: 180px;
}
.rc-calendar-month-panel-year-select-arrow {
  display: none;
}
.rc-calendar-month-panel-next-year-btn {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  right: 0;
}
.rc-calendar-month-panel-body {
  padding: 9px 10px 10px;
}
.rc-calendar-month-panel-table {
  table-layout: fixed;
  width: 100%;
  height: 255px;
  border-collapse: separate;
}
.rc-calendar-month-panel-cell {
  text-align: center;
}
.rc-calendar-month-panel-cell .rc-calendar-month-panel-month {
  display: block;
  width: 46px;
  margin: 0 auto;
  color: #666;
  border-radius: 4px 4px;
  height: 36px;
  padding: 0;
  background: transparent;
  line-height: 36px;
  text-align: center;
}
.rc-calendar-month-panel-cell .rc-calendar-month-panel-month:hover {
  background: #ebfaff;
  cursor: pointer;
}
.rc-calendar-month-panel-cell-disabled .rc-calendar-month-panel-month {
  color: #bfbfbf;
}
.rc-calendar-month-panel-cell-disabled .rc-calendar-month-panel-month:hover {
  background: white;
  cursor: not-allowed;
}
.rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month {
  background: #3fc7fa;
  color: #fff;
}
.rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month:hover {
  background: #3fc7fa;
  color: #fff;
}
.rc-calendar-year-panel {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 1000000;
  position: absolute;
  outline: none;
}
.rc-calendar-year-panel-hidden {
  display: none;
}
.rc-calendar-year-panel-header {
  padding: 0 10px;
  height: 34px;
  line-height: 30px;
  position: relative;
  text-align: center;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid #ccc;
}
.rc-calendar-year-panel-header > a {
  font-weight: bold;
  display: inline-block;
  padding: 4px 5px;
  text-align: center;
  width: 30px;
}
.rc-calendar-year-panel-header > a:hover {
  cursor: pointer;
  color: #23c0fa;
}
.rc-calendar-year-panel-prev-decade-btn,
.rc-calendar-year-panel-next-decade-btn {
  position: absolute;
  top: 0;
}
.rc-calendar-year-panel-prev-decade-btn {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  left: 0;
}
.rc-calendar-year-panel .rc-calendar-year-panel-decade-select {
  width: 180px;
}
.rc-calendar-year-panel-decade-select-arrow {
  display: none;
}
.rc-calendar-year-panel-next-decade-btn {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  right: 0;
}
.rc-calendar-year-panel-body {
  padding: 9px 10px 10px;
}
.rc-calendar-year-panel-table {
  table-layout: fixed;
  width: 100%;
  height: 255px;
  border-collapse: separate;
}
.rc-calendar-year-panel-cell {
  text-align: center;
}
.rc-calendar-year-panel-year {
  display: block;
  width: 46px;
  margin: 0 auto;
  color: #666;
  border-radius: 4px 4px;
  height: 36px;
  padding: 0;
  background: transparent;
  line-height: 36px;
  text-align: center;
}
.rc-calendar-year-panel-year:hover {
  background: #ebfaff;
  cursor: pointer;
}
.rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year {
  background: #3fc7fa;
  color: #fff;
}
.rc-calendar-year-panel-selected-cell .rc-calendar-year-panel-year:hover {
  background: #3fc7fa;
  color: #fff;
}
.rc-calendar-year-panel-last-decade-cell .rc-calendar-year-panel-year,
.rc-calendar-year-panel-next-decade-cell .rc-calendar-year-panel-year {
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}
.rc-calendar-year-panel-last-decade-cell .rc-calendar-year-panel-year:before,
.rc-calendar-year-panel-next-decade-cell .rc-calendar-year-panel-year:before {
  content: ">";
  font-family: "iconfont" !important;
}
.rc-calendar-year-panel-last-decade-cell .rc-calendar-year-panel-year {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rc-calendar-decade-panel {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 1000000;
  position: absolute;
  outline: none;
}
.rc-calendar-decade-panel-hidden {
  display: none;
}
.rc-calendar-decade-panel-header {
  padding: 0 10px;
  height: 34px;
  line-height: 34px;
  position: relative;
  text-align: center;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid #ccc;
}
.rc-calendar-decade-panel-header > a {
  font-weight: bold;
  display: inline-block;
  padding: 1px 5px;
  text-align: center;
  width: 30px;
}
.rc-calendar-decade-panel-header > a:hover {
  cursor: pointer;
  color: #23c0fa;
}
.rc-calendar-decade-panel-prev-century-btn,
.rc-calendar-decade-panel-next-century-btn {
  position: absolute;
  top: 0;
}
.rc-calendar-decade-panel-prev-century-btn {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  left: 0;
}
.rc-calendar-decade-panel-next-century-btn {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  right: 0;
}
.rc-calendar-decade-panel-body {
  padding: 9px 10px 10px;
}
.rc-calendar-decade-panel-table {
  table-layout: fixed;
  width: 100%;
  height: 255px;
  border-collapse: separate;
}
.rc-calendar-decade-panel-cell {
  text-align: center;
}
.rc-calendar-decade-panel-decade {
  display: block;
  margin: 0 auto;
  color: #666;
  border-radius: 4px 4px;
  height: 36px;
  padding: 0;
  background: transparent;
  line-height: 36px;
  text-align: center;
}
.rc-calendar-decade-panel-decade:hover {
  background: #ebfaff;
  cursor: pointer;
}
.rc-calendar-decade-panel-selected-cell .rc-calendar-decade-panel-decade {
  background: #3fc7fa;
  color: #fff;
}
.rc-calendar-decade-panel-selected-cell .rc-calendar-decade-panel-decade:hover {
  background: #3fc7fa;
  color: #fff;
}
.rc-calendar-decade-panel-last-century-cell .rc-calendar-decade-panel-decade,
.rc-calendar-decade-panel-next-century-cell .rc-calendar-decade-panel-decade {
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
}
.rc-calendar-decade-panel-last-century-cell .rc-calendar-decade-panel-decade:before,
.rc-calendar-decade-panel-next-century-cell .rc-calendar-decade-panel-decade:before {
  content: ">";
  font-family: "iconfont" !important;
}
.rc-calendar-decade-panel-last-century-cell .rc-calendar-decade-panel-decade {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
.rc-calendar-range {
  width: 502px;
  overflow: hidden;
}
.rc-calendar-range-part {
  width: 250px;
  position: relative;
}
.rc-calendar-range-left {
  float: left;
}
.rc-calendar-range-right {
  float: right;
}
.rc-calendar-range-right .rc-calendar-input-wrap {
  margin-left: -39px;
}
.rc-calendar-range-middle {
  position: absolute;
  left: 41%;
  width: 20px;
  margin-left: -10px;
  text-align: center;
  height: 35px;
  line-height: 35px;
}
.rc-calendar-range .rc-calendar-date-input-wrap,
.rc-calendar-range .rc-calendar-time-picker-wrap {
  width: 37%;
}
.rc-calendar-range .rc-calendar-time-picker-wrap {
  position: absolute;
  left: 40%;
}
.rc-calendar-range .rc-time-picker-input {
  width: 100%;
}
.rc-calendar-range .rc-calendar-input-wrap {
  height: 35px;
}
.rc-calendar-range .rc-calendar-input,
.rc-calendar-range .rc-time-picker-input {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 1px 7px;
  height: 22px;
}
.rc-calendar-range .rc-time-picker-icon {
  display: none;
}
.rc-calendar-range .rc-calendar-calendar-body,
.rc-calendar-range .rc-calendar-decade-panel-body,
.rc-calendar-range .rc-calendar-year-panel-body,
.rc-calendar-range .rc-calendar-month-panel-body {
  border-bottom: 1px solid #e9e9e9;
}
.rc-calendar-range.rc-calendar-week-number {
  width: 574px;
}
.rc-calendar-range.rc-calendar-week-number .rc-calendar-range-part {
  width: 286px;
}
.rc-calendar-range .rc-calendar-year-panel,
.rc-calendar-range .rc-calendar-month-panel {
  top: 35px;
}
.rc-calendar-range .rc-calendar-month-panel .rc-calendar-year-panel {
  top: 0;
}
.rc-calendar-range .rc-calendar-decade-panel-table,
.rc-calendar-range .rc-calendar-year-panel-table,
.rc-calendar-range .rc-calendar-month-panel-table {
  height: 198px;
}
.rc-calendar-range .rc-calendar-in-range-cell {
  background: #ebf4f8;
  border-radius: 0;
}
.rc-calendar-range-bottom {
  text-align: right;
}
.rc-calendar-range .rc-calendar-ok-btn {
  position: static;
  margin: 9px;
}
.rc-calendar-range .rc-calendar-today-btn {
  margin: 12px 9px 0;
}
.rc-calendar-full-header {
  padding: 5px 10px;
  text-align: center;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
}
.rc-calendar-full-header-month-select,
.rc-calendar-full-header-year-select {
  float: right;
  margin-right: 5px;
}
.rc-calendar-full-header-switcher {
  float: right;
  display: inline-block;
}
.rc-calendar-full-header-switcher-normal:hover {
  border-color: #23c0fa;
  box-shadow: 0 0 2px rgba(45, 183, 245, 0.8);
  cursor: pointer;
}
.rc-calendar-full-header-switcher-focus {
  border-color: #3fc7fa;
  background-color: #3fc7fa;
  color: #fff;
}
.rc-calendar-full-header-switcher > span {
  float: left;
  height: 28px;
  line-height: 24px;
  border: 1px solid #d9d9d9;
  padding: 0 10px;
  color: #666;
}
.rc-calendar-full-header-switcher > span:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
}
.rc-calendar-full-header-switcher > span:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
}
.rc-calendar-fullscreen {
  width: auto;
}
.rc-calendar-fullscreen .rc-calendar-full-header {
  border-bottom: none;
}
.rc-calendar-fullscreen .rc-calendar-column-header {
  text-align: right;
  padding-right: 12px;
}
.rc-calendar-fullscreen .rc-calendar-cell {
  padding: 0;
}
.rc-calendar-fullscreen .rc-calendar-cell .rc-calendar-date,
.rc-calendar-fullscreen .rc-calendar-month-panel-cell .rc-calendar-month-panel-month {
  display: block;
  height: 116px;
  width: auto;
  border-radius: 0;
  margin: 0 4px;
  border: none;
  border-top: 2px solid #eee;
  text-align: right;
  padding-right: 8px;
}
.rc-calendar-fullscreen .rc-calendar-selected-day .rc-calendar-date,
.rc-calendar-fullscreen .rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month {
  background-color: #ebfaff;
  color: #666;
}
.rc-calendar-fullscreen .rc-calendar-today .rc-calendar-date,
.rc-calendar-fullscreen .rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month {
  border-top-color: #3FC7FA;
  color: #3FC7FA;
}

/*.rc-calendar-table {
	width: 190px;
}*/
/*.rc-calendar-header a {
	margin-left: 10px;
	cursor: pointer;
}
.rc-calendar {
	margin-left: 9px;
}*/
.boxCalendarMax {
	position: fixed;
    top: 288px;
    left: 7px;
    z-index: 12000000;
    background: #2f3949;
    color:#fff;
    border-radius: 10px;
}
.boxCalendarMin {
	position: fixed;
    top: 288px;
    left: 7px;
    z-index: 12000000;
    background: #2f3949;
    color:#fff;
    border-radius: 10px;
}
.controlPaneladdMalls {
	height: 28px;
    width: 100px;
    background: #45b976;
    margin-left: calc((100% - 100px)/2);
    border: none;
    border-radius: 3px;
    color: #c2c7cf;
    outline: none;
}
.onAll {
	width: calc((100% - 10px)/2);
    height: 28px;
    margin-top: 10px;
    border: 0px;
    outline: none;
    border-radius: 3px;
    margin-right: 5px;
    font-size: 11px;
}
.offAll {
	width: calc((100% - 10px)/2);
    height: 28px;
    margin-top: 10px;
    border: 0px;
    outline: none;
    border-radius: 3px;
    margin-left: 5px;
    font-size: 11px;
}
.rc-calendar {
  width: 220px;
}